import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { ToastService } from './services/toast.service';
import { LoadingService } from './services/loading.service';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { ConfirmMessagesComponent } from './components/confirm-messages/confirm-messages.component';
import { PhenergyContractComponent } from './components/phenergy-contract/phenergy-contract.component';
import { ErrorAlertComponent } from './components/error-alert/error-alert.component';
import { WebSocketService } from './services/websocket';
import { NumericFormatPipe } from './pipes/numeric-format.pipe';
import { SymbolCurrencytPipe } from './pipes/symbol-currency.pipe';
import { ModalExportExcelDailyComponent } from './components/modals/modal-export-excel-daily/modal-export-excel-daily.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ModalGeneralRenewSyncComponent } from './components/modals/modal-general-renew-sync/modal-general-renew-sync.component';
import { GaugeChartComponent } from './components/gauge-chart/gauge-chart.component';
import { GaugeChartModule } from 'angular-gauge-chart';
import { GeneralModalDeleteComponent } from './components/modals/general-modal-delete/general-modal-delete.component';
import { NgDropFilesDirective } from './directives/ng-drop-files.directive';
import { ModalGeneralImportTemplateComponent } from './components/modals/modal-general-import-template/modal-general-import-template.component';
@NgModule({
    imports: [
        CommonModule,
        GaugeChartModule,
        MatSnackBarModule,
        FormsModule,
        ReactiveFormsModule,
        TranslateModule,
        NgbModule,
    ],
    declarations: [
        ConfirmMessagesComponent,
        SafeHtmlPipe,
        PhenergyContractComponent,
        ErrorAlertComponent,
        NumericFormatPipe,
        SymbolCurrencytPipe,
        ModalExportExcelDailyComponent,
        GeneralModalDeleteComponent,
        ModalGeneralRenewSyncComponent,
        GaugeChartComponent,
        NgDropFilesDirective,
        ModalGeneralImportTemplateComponent,
    ],
    exports: [
        SafeHtmlPipe,
        NumericFormatPipe,
        SymbolCurrencytPipe,
        ConfirmMessagesComponent,
        PhenergyContractComponent,
        ErrorAlertComponent,
        ModalExportExcelDailyComponent,
        ModalGeneralRenewSyncComponent,
        GeneralModalDeleteComponent,
        GaugeChartComponent,
        NgDropFilesDirective,
        ModalGeneralImportTemplateComponent
    ],
    providers: [ToastService, LoadingService, WebSocketService]
})
export class SharedModule {}
