import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'finergia-core-confirm-messages',
  templateUrl: './confirm-messages.component.html',
  styleUrls: ['./confirm-messages.component.scss']
})
export class ConfirmMessagesComponent implements OnInit {

  icon: boolean = false;
  title: string = '';
  message: string = '';
  accept: string = 'Aceptar';
  cancel: string = 'Cancelar'
  cssStyleCancel: string = 'btn btn-outline-secondary-general'
  cssStyle: string = 'btn btn-secondary-general'
  data:any;
  subTitle = '';

  constructor(public activeModal: NgbActiveModal) { }


  ngOnInit() {
    console.log(this.data, 'data');
    console.log(this.cssStyle)
  }

}
