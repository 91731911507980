import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { menu, stateMeFacade } from '@finergia-core/state-me';
import { Observable, filter } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
declare function sidebar(): any;
@Component({
  selector: 'finergia-core-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  menu$: Observable<menu[]> = new Observable();
  menu:menu[] = [];
  @Input('showSuscription') showSuscription: boolean = false;
  constructor(private facade: stateMeFacade,
    private changeDetect: ChangeDetectorRef,
    private router: Router) {
    router.events.pipe(filter(e => e instanceof NavigationStart)).subscribe(e => {
      const navigation = router.getCurrentNavigation();
      this.changeDetect.detectChanges();
    });
  }

  ngOnInit(): void {
    this.menu$ = this.facade.menu$;
    this.menu$.subscribe((data) => {
      if(data && data.length > 0){
        this.menu = data;
        setTimeout(()=>{
          this.changeDetect.detectChanges();
          sidebar();
        }, 1)
      }
    });

  }

}
