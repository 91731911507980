export const environment = {
  production: false,
  apiUrl: 'https://dev.restapi.finergia.es/api/',
  sessionTimeOut: 1,
  stripePublicKey:
    'pk_test_51KemyAKEXDcdwO4SboLCtQujMsa3nNPWzBcirDrV4GJ6JaOYwaFdDSUZRVciWSon9msMSd8qRuqq5Qlu3JSOX0LA00MQuVrsP0',
  refresh_datatable_assigned: 240000,
  websocket: {
    cluster: 'mt1',
    wsHost: 'dev.core.websocket.finergia.es',
    wssPort: 6001,
    wsPort: 6001,
    httpHost: 'localhost:6001',
    httpPort: 6001,
    enableStats: true,
    enabledTransports: ['ws'],
    forceTLS: true,
    authEndpoint: 'https://dev.restapi.finergia.es/api/broadcasting/auth',
  },
};
